<template>
  <div class="discount-promotion">
    <div class="discount-promotion__left">
      <span
        class="discount-promotion__name"
        v-html="item.leftTip"
      ></span>
      <template v-if="item.questionMark">
        &nbsp;
        <Icon
          name="sui_icon_doubt_16px_2"
          size="16px"
          class="tip-icon"
          color="#959595"
          @click="() => onOpenTip(item.questionMark)"
        />
      </template>
      <CartCountdown
        v-if="item.endTimeStamp"
        :time-stamp="+item.endTimeStamp"
        not-hide
        not-reload
        class="discount-promotion__time"
        font-size="10px"
      />
    </div>
    <div class="discount-promotion__saved">
      <span class="discount-promotion__line">
        {{ item.middleDecollatorTip }}
      </span>
      <span>{{ item.rightTip }}</span>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue2'
import CartCountdown from '../../common/CartCountDownTime.vue'

defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['open-tip'])

const onOpenTip = (text) => {
  emit('open-tip', text)
}
</script>

<style lang="less" scoped>
.discount-promotion {
  max-height: 34px;
  font-size: 12px;
  color: @sui_color_gray_light1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &__left {
    max-width: 100%;
    display: inline-flex;
    align-items: center;
  }

  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__time {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    margin-left: 4px;
    color: @sui_color_promo;
    ::v-deep .count-number {
      height: 14px;
      line-height: 14px;
      font-size: 10px;
      background-color: #FFECE9;
      border-radius: 2px;
    }
  }

  &__saved {
    display: inline;
    line-height: 17px;
  }

  &__line {
    display: inline-block;
    margin: 0 4px;
    color: #ccc;
  }
}
</style>
