<template>
  <div class="checklist-dropdown">
    <div
      :class="[
        'checklist-dropdown__bar',
        hasList && 'pointer',
      ]"
      @click="onClick"
    >
      <div class="checklist-dropdown__bar-content">
        <div class="checklist-dropdown__left-tip">
          {{ data.leftKeyTip }}&nbsp;
          <template v-if="data.questionMark">
            <Icon
              name="sui_icon_doubt_16px_2"
              size="16px"
              color="#959595"
              @click.stop="() => onOpenTip(data.questionMark)"
            />
            &nbsp;
          </template>
          <CartCountdown
            v-if="data.endTimeStamp"
            :time-stamp="+data.endTimeStamp"
            not-hide
            not-reload
            class="checklist-dropdown__time"
            font-size="10px"
          />
        </div>
        <div class="checklist-dropdown__right-price">
          <img
            v-if="data.rightValueIconUrl"
            :style="{
              marginRight: '4px',
              width: '14px',
              height: '14px',
            }"
            :src="data.rightValueIconUrl"
          />
          <span :style="rightValuePriceStyle">
            -{{ data.rightValuePrice.amountWithSymbol }}
          </span>
          <Icon
            v-if="hasList"
            name="sui_icon_more_down_16px"
            size="16px"
            color="#959595"
            :class="[
              'checklist-dropdown__icon-more',
              isOpen && 'active',
            ]"
          />
        </div>
      </div>
    </div>
    <div
      v-if="hasList && isOpen"
      class="checklist-dropdown__content j-checklist-dropdown__content"
    >
      <div
        v-for="(item, index) in data.twoLevelData"
        :key="index"
        class="checklist-dropdown__item"
        :class="['checklist-dropdown__item', `j-checklist-dropdown__item-${item.typeId}`]"
      >
        <slot
          name="item-top"
          :data="item"
        >
        </slot>
        <template v-if="item.type === '1'">
          <div class="checklist-dropdown__promotion">
            <DiscountPromotion
              :item="item"
              @open-tip="onOpenTip"
            />
          </div>
        </template>
        <template v-else-if="item.type === '2'">
          <div class="checklist-dropdown__coupon">
            <DiscountCoupon :item="item" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import DiscountPromotion from './DiscountPromotion.vue'
import DiscountCoupon from './DiscountCoupon.vue'
import CartCountdown from '../../common/CartCountDownTime.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['fold', 'open-tip'])

const rightValuePriceStyle = computed(() => ({
  color: props.data.rightValueFontColor,
}))
const hasList = computed(() => !!props.data.twoLevelData?.length)

const isOpen = ref(false)
const onClick = () => {
  if (!hasList.value) return
  isOpen.value = !isOpen.value
  emit('fold', isOpen.value, props.data)
}
const onOpenTip = (text) => {
  emit('open-tip', text)
}

defineExpose({
  onClick,
})
</script>

<style lang="less" scoped>
.checklist-dropdown {
  &__bar {
    margin: 12px 0;

    &.pointer {
      cursor: pointer;
    }
  }

  &__bar-content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    line-height: normal;
  }

  &__left-tip {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &__time {
    font-size: 10px;
    line-height: 1.15;
    color: @sui_color_promo;
    ::v-deep .count-number {
      background-color: #FFECE9;
      border-radius: 2px;
    }
  }

  &__right-price {
    flex-shrink: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: 16px;
    max-width: 65%;
    font-weight: 700;
    color: #FA6338;
    text-align: right;
    word-break: break-all;
  }

  &__icon-more {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -8px;
    color: @sui_color_gray_light1 !important; /* stylelint-disable-line declaration-no-important */

    &.active {
      transform: rotateX(180deg);
    }
  }

  &__content {
    margin: 12px 0;
  }

  &__item {
    padding: 8px;
    background: rgba(#fff, .6);
    &:first-child {
      box-shadow: 0 0.5px 0 0 #E5E5E5 inset;
    }
  }
}
</style>
