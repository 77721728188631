<template>
  <div
    v-if="show"
    class="checklist-cart"
  >
    <div class="checklist-cart__bar">
      <div
        class="checklist-carts__bar-left-tip"
        :class="{ 'checklist-cart_bold': leftTipBold }"
      >
        {{ item.selectNumDesc }}
        <span
          v-if="item.lowStockNumDesc"
          v-html="item.lowStockNumDesc"
        ></span>
      </div>
    </div>
    <div class="checklist-cart__list">
      <ProductImg
        v-for="product in products"
        :key="product.id"
        :src="product.goods_img"
        :belt="product.productImgBelt"
        :checkbox-disabled="product.goodType != '1'"
        :cart-id="product.id"
        :product-map="newProductMap"
        @click="onClickProductImg"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import ProductImg from './ProductImg.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  leftTipBold: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click-product-img'])

const products = ref([])
const show = computed(() => !!products.value.length)
const productMap = computed(() => products.value.reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {}))

const newProductMap = computed(() => props.item?.products?.reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {}))

const onClickProductImg = (data) => {
  emit('click-product-img', data)
}

watch(() => props.item.products, (v) => {
  if (products.value.length) {
    const addProducts = v.filter(f => !productMap.value[f.id])
    products.value = [...products.value.map(m => newProductMap[m.id] || m), ...addProducts]
  } else {
    products.value = v
  }
}, { immediate: true })
</script>

<style lang="less" scoped>
.checklist-cart {
  &_bold {
    font-weight: 700;
  }

  &__bar {
    margin: 12px 0;
    font-size: 14px;
    color: #000;
    line-height: normal;
  }

  &__bar-left-tip {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    gap: 6px;
    margin: 12px 0;
    overflow-x: auto;
    scrollbar-width: none;
  }
}
</style>
