<template>
  <div :class="rootClassname">
    <!-- cell -->
    <div class="checklist-cell__content">
      <div
        class="checklist-cell__left-tip"
        :class="{ 'checklist-cell__left-tip_bold': leftTipBold }"
      >
        {{ data.leftKeyTip }}
      </div>
      <div class="checklist-cell__right-price">
        {{ data.rightValuePrice.amountWithSymbol }}
      </div>
    </div>

    <!-- BNPL -->
    <div
      v-if="data.bnplDetailInfo"
      class="checklist-cell__bnpl"
    >
      <CartBNPLPopover
        :show-close="false"
        :place-order-desc="data.bnplDetailInfo.tipText"
        :payment-front-payment-list="data.bnplDetailInfo.paymentItemList"
      />
    </div>

    <!-- desc -->
    <div
      v-if="data.additionalDesc || data.additionalCrossedPrice"
      class="checklist-cell__sub"
    >
      <span v-if="data.additionalDesc">
        {{ data.additionalDesc }}&nbsp;
      </span>
      <span
        v-if="data.additionalCrossedPrice"
        class="checklist-cell__line-through"
      >
        {{ data.additionalCrossedPrice.amountWithSymbol }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CartBNPLPopover from '../../cartCheckout/CartBNPLPopover.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  leftTipBold: {
    type: Boolean,
    default: false,
  },
})

const rootClassname = computed(() => {
  return `checklist-cell checklist-cell__style-${props.data.styleType}`
})
</script>

<style lang="less" scoped>
.checklist-cell {
  margin: 12px 0;
  &__content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    line-height: normal;
  }

  &__left-tip {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    &_bold {
      font-weight: 700;
    }
  }

  &__right-price {
    flex-shrink: 0;
    position: relative;
    padding-right: 16px;
    max-width: 65%;
    font-weight: 700;
    text-align: right;
    word-break: break-all;
    color: #000;
  }

  &__sub {
    margin-top: 4px;
    font-size: 10px;
    color: @sui_color_gray_dark3;
  }

  &__line-through {
    text-decoration: line-through;
  }

  &__style-2 {
    .checklist-cell__right-price {
      color: #FA6338;
    }
  }

  &__style-3 {
    .checklist-cell__content {
      padding-top: 4px;
      font-size: 16px;
    }
    .checklist-cell__right-price {
      font-size: 18px;
    }
  }
}
</style>
