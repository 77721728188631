var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"checklist-dropdown"},[_c('div',{class:[
      'checklist-dropdown__bar',
      _setup.hasList && 'pointer',
    ],on:{"click":_setup.onClick}},[_c('div',{staticClass:"checklist-dropdown__bar-content"},[_c('div',{staticClass:"checklist-dropdown__left-tip"},[_vm._v("\n        "+_vm._s(_vm.data.leftKeyTip)+" \n        "),(_vm.data.questionMark)?[_c(_setup.Icon,{attrs:{"name":"sui_icon_doubt_16px_2","size":"16px","color":"#959595"},on:{"click":function($event){$event.stopPropagation();return (() => _setup.onOpenTip(_vm.data.questionMark)).apply(null, arguments)}}}),_vm._v("\n           \n        ")]:_vm._e(),_vm._v(" "),(_vm.data.endTimeStamp)?_c(_setup.CartCountdown,{staticClass:"checklist-dropdown__time",attrs:{"time-stamp":+_vm.data.endTimeStamp,"not-hide":"","not-reload":"","font-size":"10px"}}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"checklist-dropdown__right-price"},[(_vm.data.rightValueIconUrl)?_c('img',{style:({
            marginRight: '4px',
            width: '14px',
            height: '14px',
          }),attrs:{"src":_vm.data.rightValueIconUrl}}):_vm._e(),_vm._v(" "),_c('span',{style:(_setup.rightValuePriceStyle)},[_vm._v("\n          -"+_vm._s(_vm.data.rightValuePrice.amountWithSymbol)+"\n        ")]),_vm._v(" "),(_setup.hasList)?_c(_setup.Icon,{class:[
            'checklist-dropdown__icon-more',
            _setup.isOpen && 'active',
          ],attrs:{"name":"sui_icon_more_down_16px","size":"16px","color":"#959595"}}):_vm._e()],1)])]),_vm._v(" "),(_setup.hasList && _setup.isOpen)?_c('div',{staticClass:"checklist-dropdown__content j-checklist-dropdown__content"},_vm._l((_vm.data.twoLevelData),function(item,index){return _c('div',{key:index,staticClass:"checklist-dropdown__item",class:['checklist-dropdown__item', `j-checklist-dropdown__item-${item.typeId}`]},[_vm._t("item-top",null,{"data":item}),_vm._v(" "),(item.type === '1')?[_c('div',{staticClass:"checklist-dropdown__promotion"},[_c(_setup.DiscountPromotion,{attrs:{"item":item},on:{"open-tip":_setup.onOpenTip}})],1)]:(item.type === '2')?[_c('div',{staticClass:"checklist-dropdown__coupon"},[_c(_setup.DiscountCoupon,{attrs:{"item":item}})],1)]:_vm._e()],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }